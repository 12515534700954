<template>
  <div class="publish-product-container">
    <div class="publish-product-content">
        <!-- 游戏商品发布流程 -->
        <div class="publish-product-process-box">
            <p class="publish-product-process-title">发布商品</p>
            <ul>
                <li v-for="item in publishProductProcessSteps" :key="item.id" @click="clickProcessStepsHandler(item.id)">
                    <div class="publish-product-process-number" :style="{background:currentStepsIndex===item.id?'#F02233':''}">
                        <p class="publish-product-process-number-text" :style="{color:currentStepsIndex===item.id?'#FFFFFF':''}">0{{item.id}}</p>
                    </div>
                    <p class="publish-product-process-steps-title" :style="{color:currentStepsIndex===item.id?'#F02233':''}">{{item.stepTitle}}</p>
                </li>
                <div class="publish-product-process-line"></div>
            </ul>
        </div>
        <!-- 1、选择游戏 -->
        <publish-product-select-games v-if="currentStepsIndex===1"></publish-product-select-games>
        <!-- 2、填写游戏商品信息 -->
        <publish-product-form v-if="currentStepsIndex===2"></publish-product-form>
        <!-- 3、商品设置信息 -->
        <publish-product-setting v-if="currentStepsIndex===3"></publish-product-setting>
        <!-- 4、发布商品完成 -->
        <publish-product-done v-if="currentStepsIndex===4"></publish-product-done>
    </div>
  </div>
</template>

<script>
import PublishProductSelectGames from './components/publish-product-select-game.vue';
import PublishProductForm from './components/publish-product-form.vue';
import PublishProductSetting from './components/publish-product-setting.vue';
import PublishProductDone from './components/publish-product-done.vue';
import {mapState,mapMutations} from 'vuex';
export default {
    name:"PublishProduct",
    components:{
        PublishProductSelectGames,
        PublishProductForm,
        PublishProductSetting,
        PublishProductDone
    },
    data(){
        return{
            publishProductProcessSteps:[{id:1,stepTitle:"选择游戏"},{id:2,stepTitle:"填写商品信息"},{id:3,stepTitle:"商品设置"},{id:4,stepTitle:"提交审核并发布"}],
            
        }
    },
    methods:{
        ...mapMutations('publishProduct',['setCurrentStepsIndex']),
        clickProcessStepsHandler(index){
            this.setCurrentStepsIndex(index)
        }
    },
    computed:{
        ...mapState({
            currentStepsIndex:state=>state.publishProduct.currentStepsIndex
        })
    }
}
</script>

<style lang="less" scoped>
.publish-product-container{
    background: #F5F5F5;
    // width: calc(100vw);
    width: 1440px;
    .publish-product-content{
        margin: 0 auto;
        width: 1200px;
        .publish-product-process-box{
            padding-top: 30px;
            width: 1200px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .publish-product-process-title{
                height: 22px;
                font-family: MicrosoftYaHei, MicrosoftYaHei;
                font-weight: bold;
                font-size: 22px;
                color: #222222;
                line-height: 22px;
                text-align: left;
                font-style: normal;
            }
            ul{
                margin-top: 20px;
                list-style-type: none;
                padding: 0px;
                height: 63px;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                position: relative;
                li{
                    height: 63px;
                    margin-left: 114px;
                    list-style: none;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    cursor: default;
                    .publish-product-process-number{
                        z-index: 99;
                        width: 34px;
                        height: 34px;
                        background: #E5E5E5;
                        border-radius: 17px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .publish-product-process-number-text{
                            height: 21px;
                            font-family: MicrosoftYaHei, MicrosoftYaHei;
                            font-weight: bold;
                            font-size: 16px;
                            color: #999999;
                            line-height: 21px;
                            text-align: right;
                            font-style: normal;
                        }
                    }
                    .publish-product-process-steps-title{
                        margin-top: 10px;
                        height: 19px;
                        font-family: MicrosoftYaHei;
                        font-size: 14px;
                        color: #666666;
                        line-height: 19px;
                        text-align: right;
                        font-style: normal;
                    }
                    &:first-child{
                        margin-left: 0px;
                    }
                    &:hover .publish-product-process-number{
                        background: #F02233;
                        .publish-product-process-number-text{
                            color: #FFFFFF;
                        }
                    }
                    &:hover .publish-product-process-steps-title{
                        color: #F02233;
                    }
                }
                .publish-product-process-line{
                    z-index: 0;
                    position: absolute;
                    left: 45px;
                    top: 17px;
                    width: 524px;
                    height: 1px;
                    border: 1px dashed #C7C7C7;
                }
            }
        }

    }
}
</style>