import request from "../../utils/request";

//获取游戏名称和图标列表
export const getGameIconList = ()=>{
   return  request({
        method:'GET',
        url:'/game/getGameIconList',
    })
}

//获取最新上架游戏列表
export const getNewArrivalList = ()=>{
    return  request({
         method:'GET',
         url:'/game/getNewArrivalList',
     })
 }

 //获取主页游戏列表
 export const getHomeGameProductList = ()=>{
    return  request({
         method:'GET',
         url:'/game/getHomeGameProductList',
     })
 }

  //获取主页游戏列表
  export const getHomeSettings = ()=>{
    return  request({
         method:'GET',
         url:'/game/getHomeSettings',
     })
 }