<template>
    <div class="publish-product-select-games-box">
        <div class="publish-product-word-menu-index">
            <ul>
                <li v-for="(item,index) in charIndexArray" :key="index">
                    <p class="publish-product-word-menu-index-text">{{item}}</p>
                </li>
            </ul>
        </div>
        <div class="publish-product-games-list">
            <ul>
                <li v-for="(item,index) in gameIconList" :key="index" @click="clickGameItemHandler(item)">
                    <img class="publish-product-games-icon" :src="item.gameIcon" alt="游戏图标" >
                    <p class="publish-product-games-name">{{item.gameName}}</p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import  {mapState,mapMutations}  from 'vuex';
import { getGameIconList} from '@/services/home';
export default {
    name:"PublishProductSelectGames",
    data(){
        return{
            charIndexArray:["全部","A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
            gamesData:[]
        }
    },
    methods:{
        ...mapMutations('publishProduct',['setCurrentStepsIndex','setCurrentSelectedGameItem']),
        clickGameItemHandler(item){
            this.setCurrentSelectedGameItem(item);
            this.setCurrentStepsIndex(this.currentStepsIndex+1);
        },
        //加载游戏图标列表
        async loadGameIconList(){
            try{
                const {data} =  await getGameIconList()
                if(data.code === 200){
                    this.gameList = data.data;
                    this.$store.commit('home/setGameIconList',data.data)
                    this.gameListSelected=this.selectGameList(1);
                }
            }catch(err){}
        },
    },
    computed:{
        ...mapState({
            currentStepsIndex:state=>state.publishProduct.currentStepsIndex,
            gameIconList:state=>state.home.gameIconList
        })
    },
    mounted(){
        //如果用户是在本页面进行刷新操作，则需要重新拉取数据，因为刷新后，获取不到vuex的数据了
        if(!this.gameIconList || this.gameIconList.length===0){
            this.loadGameIconList();
        }
    }
}
</script>

<style lang="less" scoped>
.publish-product-select-games-box{
    margin-top: 30px;
    width: 1200px;
    // height: 674px;
    background: #FFFFFF;
    border-radius: 8px;
    .publish-product-word-menu-index{
        width: 1200px;
        ul{
            list-style-type: none;
            padding: 0px;
            width: 1200px;
            height: 42px;
            background: #FFFFFF;
            border-radius: 8px 8px 0px 0px;
            display: flex;
            cursor: default;
            li{
                margin-top: 18px;
                width: 43px;
                height: 16px;
                list-style: none;
                display: flex;
                justify-content: center;
                align-items: center;
                .publish-product-word-menu-index-text{
                    height: 16px;
                    font-family: MicrosoftYaHei;
                    font-size: 16px;
                    color: #222222;
                    line-height: 16px;
                    text-align: left;
                    font-style: normal;
                }
                &:first-child{
                    margin-left: 20px;
                }
                &:hover .publish-product-word-menu-index-text{
                    font-weight: bold;
                    color: #F02233;
                    transform: scale(1.2);
                }
            }
        }
    }
    .publish-product-games-list{
        width: 1200px;
        ul{
            margin-top: 30px;
            width: 1200px;
            list-style-type: none;
            padding: 0px;
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 10px;
            li{
                margin-bottom: 20px;
                margin-left: 20px;
                list-style: none;
                width: 128px;
                height: 128px;
                background: #FFFFFF;
                border: 1px solid #F0F0F0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                cursor: default;
                .publish-product-games-icon{
                    width: 56px;
                    height: 56px;
                }
                .publish-product-games-name{
                    margin-top: 10px;
                    height: 14px;
                    font-family: MicrosoftYaHei, MicrosoftYaHei;
                    font-weight: bold;
                    font-size: 14px;
                    color: #000000;
                    line-height: 14px;
                    text-align: right;
                    font-style: normal;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space:nowrap;
                }
                &:hover{
                    background: #FFF7F8;
                    border: 1px solid #F02233;
                }
                &:hover .publish-product-games-icon{
                    transition: all 0.3s ease-in-out;
                    transform: scale(1.1);
                }
                &:hover .publish-product-games-name{
                    color: #F02233;
                }
            }
        }
    }
}
</style>